import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import TitleBanner from "../TitleBanner/TitleBanner";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { object, string } from "yup";
import * as Yup from "yup";
import { useState } from "react";
import { generateAccessCode } from "../../utils/util";
import isEmpty from "lodash-es/isEmpty";
import { entityDTO } from "../../utils/Types/DTO";
import { registerEntityPublic } from "../../API/services";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/noshadow.png";
import { useNotificationStore } from "../../store/NotificationStore";
import { success } from "../Notification/NotificationMessages";

const PublicRegister = () => {
  const setNotification = useNotificationStore(
    (state) => state.setNotification
  );
  const navigate = useNavigate();
  const [accessCode] = useState(generateAccessCode());

  // Validation schema
  const validationSchema = object().shape({
    username: string().required("Email is required").email("Email is invalid"),
    confirm_username: Yup.string()
      .required("Confirm Email is required")
      .oneOf([Yup.ref("username"), ""], "Emails must match"),
    organizationName: string().required("Organization Name is required"),
    organizationMailingAddress: string().optional(),
    organizationMailingAddressZipCode: string().optional(),
    organizationMailingAddressState: string().optional(),
    organizationMailingAddressCountry: string().optional(),
    organizationAddress: string().required("Organization Address is required"),
    organizationAddressZipCode: string().required("Organization ZipCode is required"),
    organizationAddressState: string().required("Organization State is required"),
    organizationAddressCountry: string().required("Organization Country is required"),
    organizationAddressCity: string().required("Organization City is required"),
    organizationMailingAddressCity: string().optional(),
    firstName: string().required("First Name is required"),
    lastName: string().required("Last Name is required"),
    phone: string().required("Phone is required"),
    organizationWebsite: Yup.string()
    .optional()
    .matches(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#?=&_.-]*)?$/,
      "Invalid URL format"
    ),  
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const methods = useForm(formOptions);
  const { handleSubmit } = methods;
  const formState = useFormState({
    control: methods.control,
  });

  const onFormSubmit = (data: FieldValues) => {
    if (
      !isEmpty(data.firstName) &&
      !isEmpty(data.lastName) &&
      !isEmpty(data.organizationName) &&
      !isEmpty(data.phone) &&
      !isEmpty(data.username)
    ) {
      const payload: entityDTO = {
        organizationName: data.organizationName,
        accessCode: accessCode,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.username,
        phone: data.phone,
        organizationMailingAddress: data.organizationMailingAddress ?? "",
        organizationMailingAddressZipCode:
          data.organizationMailingAddressZipCode ?? "",
        organizationMailingAddressState:
          data.organizationMailingAddressState ?? "",
        organizationMailingAddressCountry:
          data.organizationMailingAddressCountry ?? "",
        organizationAddress: data.organizationAddress ?? "",
        organizationAddressZipCode: data.organizationAddressZipCode ?? "",
        organizationAddressState: data.organizationAddressState ?? "",
        organizationAddressCountry: data.organizationAddressCountry ?? "",
        organizationAddressCity: data.organizationAddressCity ?? "",
        organizationMailingAddressCity:
          data.organizationMailingAddressCity ?? "",
        organizationWebsite: data.organizationWebsite ?? "",
      };
      submitData(payload);
    }
  };

  const submitData = (payload: entityDTO) => {
    registerEntityPublic(payload)
      .then(async (response) => {
        if (response.data) {
          console.log(response.data);
          navigate("/login");
          setNotification(
            success("Please check your email for further instructions")
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container component="main">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
      onClick={() => navigate("/")}
        src={logo}
        alt="Logo"
        style={{ marginBottom: "16px", height: "40px", width: "200px", cursor: "pointer" }}
      />
      <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
        Register
      </Typography>
      <Paper elevation={3} sx={{ padding: 4, width: "100%", maxWidth: 800 }}>
        <FormProvider {...methods}>
          <Box component="form" onSubmit={handleSubmit(onFormSubmit)}>
            {/* Organization Details Section */}
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              Organization Details
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="organizationName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Organization Name"
                      error={!!formState.errors.organizationName}
                      helperText={formState.errors.organizationName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="organizationWebsite"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Organization Website"
                      error={!!formState.errors.organizationWebsite}
                      helperText={formState.errors.organizationWebsite?.message}
                      placeholder="https://www.example.com"
                    />
                  )}
                />
              </Grid>
            </Grid>
  
            {/* Mailing Address Section */}
            <Typography variant="h6" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
              Organization Mailing Address Details
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationMailingAddress"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Mailing Address"
                      error={!!formState.errors.organizationMailingAddress}
                      helperText={formState.errors.organizationMailingAddress?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationMailingAddressCity"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="City"
                      error={!!formState.errors.organizationMailingAddressCity}
                      helperText={formState.errors.organizationMailingAddressCity?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationMailingAddressState"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="State"
                      error={!!formState.errors.organizationMailingAddressState}
                      helperText={formState.errors.organizationMailingAddressState?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationMailingAddressZipCode"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Zip Code"
                      error={!!formState.errors.organizationMailingAddressZipCode}
                      helperText={formState.errors.organizationMailingAddressZipCode?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationMailingAddressCountry"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Country"
                      error={!!formState.errors.organizationMailingAddressCountry}
                      helperText={formState.errors.organizationMailingAddressCountry?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
  
            {/* Organization Address Section */}
            <Typography variant="h6" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
              Organization Address Details
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationAddress"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Address"
                      error={!!formState.errors.organizationAddress}
                      helperText={formState.errors.organizationAddress?.message}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationAddressCity"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="City"
                      error={!!formState.errors.organizationAddressCity}
                      helperText={formState.errors.organizationAddressCity?.message}
                      required
                      />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationAddressState"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="State"
                      error={!!formState.errors.organizationAddressState}
                      helperText={formState.errors.organizationAddressState?.message}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationAddressZipCode"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Zip Code"
                      error={!!formState.errors.organizationAddressZipCode}
                      helperText={formState.errors.organizationAddressZipCode?.message}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="organizationAddressCountry"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Country"
                      error={!!formState.errors.organizationAddressCountry}
                      helperText={formState.errors.organizationAddressCountry?.message}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
  
            {/* Account Owner Section */}
            <Typography variant="h6" sx={{ mt: 4, mb: 2, fontWeight: "bold" }}>
              Account Owner
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="First Name"
                      error={!!formState.errors.firstName}
                      helperText={formState.errors.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Last Name"
                      error={!!formState.errors.lastName}
                      helperText={formState.errors.lastName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Email Address"
                      error={!!formState.errors.username}
                      helperText={formState.errors.username?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="confirm_username"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Confirm Email Address"
                      error={!!formState.errors.confirm_username}
                      helperText={formState.errors.confirm_username?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Phone"
                      error={!!formState.errors.phone}
                      helperText={formState.errors.phone?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
  
            {/* Submit Button */}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Button type="submit" variant="contained" color="primary" sx={{ px: 5 }}>
                Register
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Paper>
    </Box>
  </Container>
  
  );
};

export default PublicRegister;
