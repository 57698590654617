import { Box, Button, Container, CssBaseline, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import TitleBanner from "../TitleBanner/TitleBanner";
import { useEffect, useState } from "react";
import { savePassword, validateResetToken } from "../../API/services";
import { useNotificationStore } from "../../store/NotificationStore";
import { success, failed, warning } from "../Notification/NotificationMessages";
import { isEmpty } from "lodash-es";
import { PasswordChangeDTO } from "../../utils/Types/DTO";

const ChangePassword = () => {
  let { token } = useParams<{ token: string }>();
  token = token ?? "";
  const navigate = useNavigate();
  const setNotification = useNotificationStore((state) => state.setNotification);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [newPassword, setNewPassword] = useState({ password: '', confirmPassword: '' });

  useEffect(() => {
    const validateToken = async () => {
      if (token) {
        try {
          const response = await validateResetToken(token);
          if (response.status === 200) {
            setIsTokenValid(true);
            setNotification(success('Token is Valid'));
          } else {
            navigate('/login');
            setNotification(failed('Invalid Token'));
          }
        } catch (error) {
          navigate('/login');
          setNotification(failed('Invalid Token'));
        }
       
      }

    };
    validateToken();
    console.log(token);
  }, [token]);

  const handleChange = async () => {
    if (isEmpty(newPassword.password) || isEmpty(newPassword.confirmPassword)) {
      setNotification(warning('Empty Password Provided'));
      return;
    }
    if (newPassword.password != newPassword.confirmPassword) {
      setNotification(warning('Both passwords must be same'));
      return;
    }
    if (token) {
      const payload: PasswordChangeDTO = {
        token: token,
        password: newPassword.password
      };

      const response = await savePassword(payload);

      if (response.status = 200) {
        navigate('/login');
        setNotification(success('Password Changed Successfully'));
      }
      else{
        setNotification(failed('Operation Failed'));
      }
    }

  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ mt: 4 }}>
      <TitleBanner  title={"Change Password"} arrow={false} />
      </Box>
     
      <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
           
        {isTokenValid && (
          <>
         
            <TextField
              label="New Password"
              type="password"
              value={newPassword.password}
              onChange={(e) => setNewPassword((prevState) => ({ ...prevState, password: e.target.value }))}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Confirm Password"
              type="text"
              value={newPassword.confirmPassword}
              onChange={(e) => setNewPassword((prevState) => ({ ...prevState, confirmPassword: e.target.value }))}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleChange}>
              Update
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ChangePassword;