import * as Yup from "yup";
import { isEmpty } from "lodash-es";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TitleBanner from "../TitleBanner/TitleBanner";
import { yupResolver } from "@hookform/resolvers/yup";
import { editEntity, getOrganizationdetailsById } from "../../API/services/UserServices";
import {
  useForm,
  Controller,
  useFormState,
  FormProvider,
} from "react-hook-form";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { getErrorMessage } from "../../utils/util";
import { failed, success } from "../Notification/NotificationMessages";
import { useNotificationStore } from "../../store/NotificationStore";
import { useEffect, useState } from "react";
import { organizationDTO } from "../../utils/Types/DTO";

const EditOrganization = () => {
    let { organizationId } = useParams<{ organizationId: string }>();
    organizationId = atob(organizationId ?? "");
  const location = useLocation();
  const navigate = useNavigate();
  const setNotification = useNotificationStore((state) => state.setNotification);
  // let orgId = "";
  // const [organizationDetails, setOrganizationDetails] = useState<organizationDTO>(isEmpty(organizationId)?location?.state:undefined);
  
  // if(isEmpty(organizationId)){
  //   orgId = location?.state?.orgId;
  // }
  const [organizationDetails, setOrganizationDetails] = useState<organizationDTO | undefined>();

  useEffect(() => {
    if (organizationId) {
      // Fetch organization details if organizationId exists
      const fetchOrganizationDetails = async () => {
        if(organizationId){
          try {
            const orgDetails = await getOrganizationdetailsById(organizationId);
            if (orgDetails.data) {
              setOrganizationDetails(orgDetails.data);
              reset(orgDetails.data); // Update form values dynamically
            }
          } catch (error) {
            setNotification(failed(getErrorMessage(error)));
            console.error(error);
          }
        }
        
      };
      fetchOrganizationDetails();
    } else if (location.state) {
      // orgId = location?.state?.orgId;
      // Use details from location.state if available
      setOrganizationDetails(location.state);
      reset(location.state); // Update form values dynamically
    }
  }, [organizationId, location.state, setNotification]);

  // const orgDetails = organizationDetails;


  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required("Organization Name is required"),
    organizationPhone: Yup.string()
      .required("Phone number is required")
      .matches(/^\+?[0-9]{1,15}$/, "Invalid phone number format"),
    organizationAddress: Yup.string().required("Address is required"),
    organizationAddressCity: Yup.string().required("City is required"),
    organizationAddressZipCode: Yup.string().required("ZIP Code is required"),
    organizationAddressState: Yup.string().required("State is required"),
    organizationAddressCountry: Yup.string().required("Country is required"),
    organizationMailingAddress: Yup.string().required("Mailing Address is required"),
    organizationMailingAddressCity: Yup.string().required("City is required"),
    organizationMailingAddressZipCode: Yup.string().required("ZIP Code is required"),
    organizationMailingAddressState: Yup.string().required("State is required"),
    organizationMailingAddressCountry: Yup.string().required("Country is required"),
    organizationWebsite: Yup.string()
    .required("URL is required")
    .matches(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#?=&_.-]*)?$/,
      "Invalid URL format"
    ),  
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      organizationName: organizationDetails?.organizationName||"",
      organizationPhone: organizationDetails?.organizationPhone || "",
      organizationAddress: organizationDetails?.organizationAddress || "",
      organizationAddressZipCode: organizationDetails?.organizationAddressZipCode || "",
      organizationAddressState: organizationDetails?.organizationAddressState || "",
      organizationAddressCountry: organizationDetails?.organizationAddressCountry || "",
      organizationMailingAddress: organizationDetails?.organizationMailingAddress || "",
      organizationMailingAddressZipCode: organizationDetails?.organizationMailingAddressZipCode || "",
      organizationMailingAddressState: organizationDetails?.organizationMailingAddressState || "",
      organizationMailingAddressCountry: organizationDetails?.organizationMailingAddressCountry || "",
      organizationWebsite: organizationDetails?.organizationWebsite || "",
      organizationAddressCity: organizationDetails?.organizationAddressCity || "",
      organizationMailingAddressCity: organizationDetails?.organizationMailingAddressCity || "",
    },
  });

  const { handleSubmit, control,reset } = methods;
  const { errors } = useFormState({ control });

  const onFormSubmit = (data: any) => {
    if (!isEmpty(data)) {
      const payload = {
        organizationId: organizationId?organizationId:location?.state?.orgId,
        ...data,
      };
      submitEdit(payload);
    }
  };

  const submitEdit = (payload: any) => {
    editEntity(payload)
      .then((response: any) => {
        if (response.data) {
          console.log(response.data);
          setNotification(success("Organization updated successfully"));
          routeToOrg();
        }
      })
      .catch((error) => {
        setNotification(failed(getErrorMessage(error)));
        console.error(error);
      });
  };

  const routeToOrg = () => organizationId?navigate("/home"):navigate("/org");

  return (
    <>
      <Container component="main">
        <CssBaseline />
        <TitleBanner title="Edit Organization" />
        <Paper elevation={3} style={{ padding: 15, margin: "10px 2px" }}>
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={handleSubmit(onFormSubmit)}
              noValidate
              sx={{ mt: 2 }}
            >
              <Grid container spacing={2}>
                {/* Organization Name */}
                <Grid item xs={12}>
                  <Controller
                    name="organizationName"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="Organization Name"
                        fullWidth
                        error={!!errors.organizationName}
                        helperText={errors.organizationName?.message || ""}
                      />
                    )}
                  />
                </Grid>

                {/* Organization Phone */}
                <Grid item xs={12}>
                  <Controller
                    name="organizationPhone"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="Phone"
                        fullWidth
                        error={!!errors.organizationPhone}
                        helperText={errors.organizationPhone?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="organizationWebsite"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="Website"
                        fullWidth
                        error={!!errors.organizationWebsite}
                        helperText={errors.organizationWebsite?.message || ""}
                        placeholder="https://www.example.com"
                      />
                    )}
                  />
                </Grid>

                {/* Organization Address */}
                <Grid item xs={12}sm={6}>
                  <Controller
                    name="organizationAddress"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="Address"
                        fullWidth
                        error={!!errors.organizationAddress}
                        helperText={errors.organizationAddress?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationAddressCity"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="City"
                        fullWidth
                        error={!!errors.organizationAddressCity}
                        helperText={errors.organizationAddressCity?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationAddressState"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="State"
                        fullWidth
                        error={!!errors.organizationAddressState}
                        helperText={errors.organizationAddressState?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationAddressZipCode"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="ZIP Code"
                        fullWidth
                        error={!!errors.organizationAddressZipCode}
                        helperText={errors.organizationAddressZipCode?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}sm={6}>
                  <Controller
                    name="organizationAddressCountry"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label=" Country"
                        fullWidth
                        error={!!errors.organizationAddressCountry}
                        helperText={errors.organizationAddressCountry?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}sm={6}></Grid>

                {/* Mailing Address */}
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddress"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="Mailing Address"
                        fullWidth
                        error={!!errors.organizationMailingAddress}
                        helperText={errors.organizationMailingAddress?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddressCity"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="Mailing Address City"
                        fullWidth
                        error={!!errors.organizationMailingAddressCity}
                        helperText={errors.organizationMailingAddressCity?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddressState"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="Mailing Address State"
                        fullWidth
                        error={!!errors.organizationMailingAddressState}
                        helperText={errors.organizationMailingAddressState?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddressZipCode"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="Mailing Address ZIP Code"
                        fullWidth
                        error={!!errors.organizationMailingAddressZipCode}
                        helperText={errors.organizationMailingAddressZipCode?.message || ""}
                      />
                    )}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddressCountry"
                    control={control}
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        label="Mailing Address Country"
                        fullWidth
                        error={!!errors.organizationMailingAddressCountry}
                        helperText={errors.organizationMailingAddressCountry?.message || ""}
                      />
                    )}
                  />
                  <Grid item xs={12}sm={6}></Grid>
                </Grid>

                {/* Submit and Cancel Buttons */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" fullWidth>
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Button fullWidth sx={{ mt: 2 }} variant="contained" onClick={routeToOrg}>
              Cancel
            </Button>
          </FormProvider>
        </Paper>
      </Container>
    </>
  );
};

export default EditOrganization;
