import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import TitleBanner from "../TitleBanner/TitleBanner";
import { addSupervisor } from "../../API/services";
import { useNotificationStore } from "../../store/NotificationStore";
import { warning, failed, success } from "../Notification/NotificationMessages";
import { useNavigate, useParams } from "react-router-dom";
import { add, isEmpty, zip } from "lodash";
import { getErrorMessage, getRoleToken } from "../../utils/util";

const AddAdmin: React.FC = () => {
  let { organizationId } = useParams<{ organizationId: string }>();
  organizationId = atob(organizationId ?? "");

  const getRole = getRoleToken();

  useEffect(() => {
    if (getRoleToken() !== "ADMINISTRATOR" && getRoleToken() !== "SUPER_USER") {
      window.location.href = "/";
    }
  }, []);

  const navigate = useNavigate();
  const [isPermissionSet, setIsPermissionSet] = useState(true);
  const setNotification = useNotificationStore(
    (state) => state.setNotification
  );
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    tag: "",
    manager: "",
    state: "",
    country: "",
    address: "",
    zipCode: "",
    city: "",
    role: "ADMINISTRATOR",
    confirm_email: "",
    // ... other fields can be added here
  });
  const [role, setRole] = useState<string | null>("ADMINISTRATOR");
  const [permissions, setPermissions] = useState({
    directReports: true,
    addDr: true,
    editDr: true,
    removeDr: true,
    transferDr: true,
    hr: true,
    global: true,
    screen: true,
    grow: true,
    // ... other permissions can be added here
  });
  const generateCommaSeparatedString = (permissions: {
    [key: string]: boolean;
  }) => {
    return Object.keys(permissions)
      .filter((key) => permissions[key])
      .join(",");
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (formData.email !== formData.confirm_email) {
      setNotification(warning("Emails do not match"));
      return;
    }
    if (
      !isEmpty(role) &&
      (permissions.addDr === true ||
        (permissions.directReports === true && permissions.editDr === true) ||
        permissions.removeDr === true ||
        permissions.transferDr === true ||
        permissions.hr === true ||
        permissions.global === true ||
        permissions.screen === true ||
        permissions.grow === true)
    ) {
      setIsPermissionSet(true);
      // Perform validation and submission logic here
      // console.log(formData);
      // console.log(generateCommaSeparatedString(permissions));

      const payload = {
        country: formData.country,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        group: "GRP",
        manager: formData.manager,
        fullName: `${formData.firstName} ${formData.lastName}`,
        phone: formData.phone,
        province: formData.state,
        role: "ADMINISTRATOR",
        state: formData.state,
        yearOfBirth: "",
        tag: role,
        permissions: generateCommaSeparatedString(permissions),
        organizationId: organizationId,
        address: formData.address,
        zipCode: formData.zipCode,
      };

      console.log(payload);

      try {
        const response = await addSupervisor(payload);
        if (response.data) {
          navigate("/supervisors");
          setNotification(success("Admin added successfully"));
        }
      } catch (error: any) {
        if (
          error.response.data.message.indexOf(`because "manager" is null`) > -1
        ) {
          setNotification(failed("Manager not found"));
        } else {
          setNotification(failed(getErrorMessage(error.response.data)));
        }
      }
    } else {
      if (
        isEmpty(role) &&
        permissions.addDr === false &&
        permissions.directReports === false &&
        permissions.editDr === false &&
        permissions.removeDr === false &&
        permissions.transferDr === false &&
        permissions.hr === false &&
        permissions.global === false &&
        permissions.screen === false &&
        permissions.grow === false
      ) {
        setIsPermissionSet(false);
        setNotification(failed("Please select role and permission"));
      } else if (isEmpty(role)) {
        setIsPermissionSet(true);
        setNotification(failed("Please select role"));
      } else if (
        permissions.addDr === false &&
        permissions.directReports === false &&
        permissions.editDr === false &&
        permissions.removeDr === false &&
        permissions.transferDr === false &&
        permissions.hr === false &&
        permissions.global === false &&
        permissions.screen === false &&
        permissions.screen === false
      ) {
        setIsPermissionSet(false);
        setNotification(failed("Please select permission"));
      }
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <TitleBanner title={"Add Administrator"} />

      <form onSubmit={handleSubmit}>
        <Paper elevation={3} style={{ padding: "20px", margin: "10px" }}>
          <Box
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1>Add Administrator</h1>
            <Grid direction="row" container justifyContent="flex-start">
              <h4 style={{ color: "#0088F2" }}>Personal Information</h4>
            </Grid>
            {/* Supervisor Information */}
            <Grid direction={"row"} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  label="Admin First Name"
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  label="Admin Last Name"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  type="email"
                  label="Admin Email "
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  type="email"
                  label="Admin Confirm Email"
                  value={formData.confirm_email}
                  onChange={(e) =>
                    setFormData({ ...formData, confirm_email: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  label="Admin Phone"
                  type="text"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  type="email"
                  label="Admin Manager"
                  value={formData.manager}
                  onChange={(e) =>
                    setFormData({ ...formData, manager: e.target.value })
                  }
                  required
                />
              </Grid>
            </Grid>
          </Box>
            {/* Address */}
            <Grid container>
            <h4 style={{ color: "#0088F2", display: "block" }}>Address Details</h4>
            </Grid>
            <Grid direction={"row"} container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  label="Admin Address"
                  value={formData.address}
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  label="Admin City"
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  label="Admin State"
                  value={formData.state}
                  onChange={(e) =>
                    setFormData({ ...formData, state: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  label="Admin ZipCode"
                  value={formData.zipCode}
                  onChange={(e) =>
                    setFormData({ ...formData, zipCode: e.target.value })
                  }
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth={true}
                  label="Admin Country"
                  value={formData.country}
                  onChange={(e) =>
                    setFormData({ ...formData, country: e.target.value })
                  }
                  required
                />
              </Grid>
            </Grid>




          {/* Permissions */}
          <Grid container>
            <h4 style={{ color: "#0088F2", display: "block" }}>Permissions</h4>
          </Grid>
          <Grid direction={"row"} container spacing={2}>
            <Grid item xs={3}>
              <Tooltip title="This Grants Direct Reports Permission">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.directReports}
                      disabled={true}
                      onChange={() =>
                        setPermissions({
                          ...permissions,
                          directReports: !permissions.directReports,
                        })
                      }
                    />
                  }
                  label="Direct Reports (DR)"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="This Grants Hiring Manager Permission">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.hr}
                      disabled={true}
                      onChange={() =>
                        setPermissions({
                          ...permissions,
                          hr: !permissions.hr,
                          screen: !permissions.screen,
                        })
                      }
                    />
                  }
                  label="HR"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="This Grants View Only Permission">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.global}
                      disabled={true}
                      onChange={() =>
                        setPermissions({
                          ...permissions,
                          global: !permissions.global,
                        })
                      }
                    />
                  }
                  label="Global (view only)"
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid direction={"row"} container spacing={2}>
            <Grid item xs={3}>
              <Tooltip title="This Grants Add Direct Reports Permission">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.addDr}
                      disabled={true}
                      onChange={() =>
                        setPermissions({
                          ...permissions,
                          addDr: !permissions.addDr,
                        })
                      }
                    />
                  }
                  label="Add DR"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="This Grants Edit Direct Reports Permission">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.editDr}
                      disabled={true}
                      onChange={() =>
                        setPermissions({
                          ...permissions,
                          editDr: !permissions.editDr,
                        })
                      }
                    />
                  }
                  label="Edit DR"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="This Grants Remove Direct Reports Permission">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.removeDr}
                      disabled={true}
                      onChange={() =>
                        setPermissions({
                          ...permissions,
                          removeDr: !permissions.removeDr,
                        })
                      }
                    />
                  }
                  label="Remove DR"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="This Grants Transfer Reports Permission">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.transferDr}
                      disabled={true}
                      onChange={() =>
                        setPermissions({
                          ...permissions,
                          transferDr: !permissions.transferDr,
                        })
                      }
                    />
                  }
                  label="Transfer DR"
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid direction={"row"} container spacing={2}>
            <Grid item xs={3}>
              {/* Conditional Checkbox for Screen */}
              <Tooltip title="This Grants ESAT Screen Access Permission For HR">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.screen}
                      onChange={() =>
                        setPermissions({
                          ...permissions,
                          screen: !permissions.screen,
                        })
                      }
                      disabled={true}
                    />
                  }
                  label="Screen"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="This Grants ESAT Grow Access Permission">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions.grow}
                      onChange={() =>
                        setPermissions({
                          ...permissions,
                          grow: !permissions.grow,
                        })
                      }
                      disabled={true}
                    />
                  }
                  label="Grow"
                />
              </Tooltip>
            </Grid>
            {/* ... Other Checkboxes for Permissions */}
            {!isPermissionSet && (
              <Grid direction="row" container item justifyContent="flex-start">
                <p style={{ color: "#FF0000" }}>Please select permission</p>
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent="flex-end">
            <Button variant="contained" type="submit">
              Add Administrator
            </Button>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
};
export default AddAdmin;
