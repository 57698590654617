import React from 'react';
import { Container, Paper, Typography, Button, Box } from '@mui/material';

interface LogoutPopupProps {
  open: boolean;
  onConfirm: () => void;
}

const LogoutPopup: React.FC<LogoutPopupProps> = ({ open, onConfirm }) => {
  if (!open) return null; // Don't render the popup if not open

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add overlay
        zIndex: 1300,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          textAlign: 'center',
          borderRadius: 3,
          maxWidth: 400,
        }}
      >
        <Box mb={3}>
          <Typography variant="h5" component="h1" sx={{ fontWeight: 600 }}>
            Session Expired
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1.5, lineHeight: 1.6 }}>
            Your session has expired. Please log in again to continue.
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          sx={{ paddingX: 4, paddingY: 1.2, borderRadius: 2 }}
        >
          Login
        </Button>
      </Paper>
    </Container>
  );
};

export default LogoutPopup;
