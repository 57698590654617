import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from '@mui/material';
import { UserDetailsDTO } from '../../utils/Types/DTO';

interface ViewDialogProps {
    open: boolean;
    onClose: () => void;
    user: UserDetailsDTO;
}

const ViewDialog: React.FC<ViewDialogProps> = ({ open, onClose, user }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} marginTop={1}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>First Name:</strong> {user.firstName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>Last Name:</strong> {user.lastName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>Manager:</strong> {user.manager}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>Phone:</strong> {user.phone}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>Address:</strong> {user.address}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>Zip Code:</strong> {user.zipCode}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>State:</strong> {user.state}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>Country:</strong> {user.country}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>Year Of Birth:</strong> {user.yearOfBirth}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1"><strong>Email:</strong> {user.email}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewDialog;
