import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import { setupAuthInterceptor } from "./API/authInterceptor";
import { PollingHandler } from "./utils/pollingHandler";
import CustomNotification from "./components/Notification/CustomNotification";
import GlobalLoader from "./API/GlobalLoader";
import { PopupProvider } from "./context/PopupContext";
// import GlobalLoader from './API/useAxiosLoader';

function App() {

  setupAuthInterceptor();

  return (
    <>
      <GlobalLoader />
      <Router>
      <PopupProvider>
        <PollingHandler />
        <CustomNotification />
        <Routes />
      </PopupProvider>
      </Router>
    </>
  );
}

export default App;
