import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Checkbox, FormControlLabel } from "@mui/material";

interface DeleteOrganizationDialogProps {
  open: boolean;
  onClose: () => void;
  onDeleteConfirm: () => void;
}

const DeleteOrganizationDialog: React.FC<DeleteOrganizationDialogProps> = ({ open, onClose, onDeleteConfirm }) => {
  const [step, setStep] = useState(1);
  const [agreed, setAgreed] = useState(false);

  // Reset state when closing
  const handleClose = () => {
    setStep(1);
    setAgreed(false);
    onClose();
  };

  const handleAgree = () => {
    if (agreed) {
      setStep(2);
    }
  };

  const handleDelete = () => {
    onDeleteConfirm(); // Trigger delete function
    handleClose(); // Reset state and close
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{step === 1 ? "Confirm Deletion" : "Final Confirmation"}</DialogTitle>
      <DialogContent>
        {step === 1 ? (
          <>
            <Typography color="error" fontWeight="bold">
              Warning: This operation is irreversible!
            </Typography>
            <Typography>
              Deleting this organization will permanently remove all associated data. Please confirm before proceeding.
            </Typography>
            <FormControlLabel
              control={<Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)} />}
              label="I understand that this action cannot be undone"
            />
          </>
        ) : (
          <>
            <Typography color="error" fontWeight="bold">
              Final Warning!
            </Typography>
            <Typography>
              This is your last chance to cancel. Clicking "Proceed to Delete" will permanently delete the organization.
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {step === 1 ? (
          <>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <Button onClick={handleAgree} color="warning" disabled={!agreed}>
              I Agree
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error" variant="contained">
              Proceed to Delete
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrganizationDialog;
