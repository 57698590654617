import axios from './axios';
import { getAuthToken, getSessionToken } from '../utils/util';
import publicAllowedUrlPaths from '../routes/publicPaths';

const Axios: any = axios;

export const setupAuthInterceptor = (showPopup?: () => void) => {
  Axios.interceptors.request.use(
    (config: any) => {
      const authCookie = getAuthToken();
      const sessionID = getSessionToken();

      if (authCookie) {
        // console.log(`Authorization: Bearer ${authCookie}`);
        config.headers.Authorization = 'Bearer ' + authCookie;
      }
      if (sessionID) {
        // console.log(`SessionID: ${sessionID}`);
        config.headers.sessionID = sessionID;
      }
      config.withCredentials = true;

      return config;
    },
    (error: any) => Promise.reject(error)
  );

  Axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (!publicAllowedUrlPaths.includes(window.location.pathname)) {
        if (error.response?.status === 401) {
          console.log('401 Unauthorized: Showing popup and invalidating tokens.');
          showPopup && showPopup(); // Trigger the popup
        }
      }
      return Promise.reject(error);
    }
  );
};
