import {
  Box,
  Button,
  Chip,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
    deleteUser,
  getCandidates,
  getLoggedInUserDetails,
  getSupervisors,
  updateUserDetails,
} from "../../API/services";
import {
  getCookieByName,
  getErrorMessage,
  getLoggedInUser,
  getPermissions,
  getRoleToken,
  hasPermissions,
} from "../../utils/util";
import { UserDetailsDTO, userListType } from "../../utils/Types/DTO";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import TransferDialog from "./TransferDialog";
import { filter, isEmpty } from "lodash-es";
import { useNotificationStore } from "../../store/NotificationStore";
import {
  failed,
  success,
} from "../../components/Notification/NotificationMessages";
import UpdateDialog from "./UpdateUserDialog";
import NoDataCard from "../../components/NoDataCard/NoDataCard";
import { useUserContext } from "../../context/UserContext";
import DeleteUserDialog from "./DeleteUserDialog";

type props = {
  bgColor: string;
};
const StyleChip = styled(Chip)(({ bgColor }: props) => ({
  backgroundColor: bgColor,
  borderRadius: 3,
}));
const Listing = () => {
  const loggedInUserDetails = useUserContext();
  const navigate = useNavigate();
  const getLoggedInUserEmail = getLoggedInUser();
  const getRole = getRoleToken();
  const permissions = (getPermissions() ?? "").split(",") ?? [""];
  const setNotification = useNotificationStore(
    (state) => state.setNotification
  );
  const [userList, setUserList] = useState<userListType[]>();
  const [selectedUserDetails, setSelectedUserDetails] =
    useState<UserDetailsDTO>();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [existingManager, setExistingManager] = useState(""); // You can set this dynamically.
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [refresh, setRefresh] = useState(-1);
  const [deleteUserDialogState, setDeleteUserDialogState] = useState(false);
  useEffect(() => {
    // console.log("triggered");
    const getCandidatesList = async () => {
      if (getLoggedInUserEmail) {
        const response = await getCandidates(getCookieByName("userId") ?? "");
        if (response.data.list) {
          setUserList(response.data.list);
        }
      }
    };
    getCandidatesList();
  }, [refresh]);
  const getUserDetails = async (userId: string) => {
    let loggedInUserDetailsResponse;
    try {
      loggedInUserDetailsResponse = await getLoggedInUserDetails(userId);
      if (loggedInUserDetailsResponse.data)
        setSelectedUserDetails(loggedInUserDetailsResponse.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
    return loggedInUserDetailsResponse;
  };

  const handleOpenTransferDialog = async (userId: string) => {
    const response = await getUserDetails(userId);
    // if(loggedInUserDetails.manager??''){

    if (response&&response.data) {
      setExistingManager(response.data.manager ?? "No Manager Assigned");
      setDialogOpen(true);
    } else {
      setNotification(failed("Transfer is not possible for this user"));
    }

    // }
  };

  const handleTransfer = async (newManager: string) => {
    // Make your API call here using the existingManager and newManager.
    // After successful transfer, refresh your user listing if needed.
    if (selectedUserDetails) {
      const tempData = selectedUserDetails;
      tempData.manager = newManager;
      console.log(tempData);

      const updateDetailsResponse = await updateUserDetails(tempData);

      if (updateDetailsResponse.data) {
        setDialogOpen(false);
        setNotification(success("User updated Successfully"));
      }
    }
  };

//   const handleOpenUpdateDialog = async (userId: string) => {
//     await getUserDetails(userId);
//     //    setExistingManager(data.manager);
//     if(selectedUserDetails)
//         setIsUpdateDialogOpen(true);
//     // setIsUpdateDialogOpen(true);
//   };

const handleOpenUpdateDialog = async (userId: string) => {
    const userDetails = await getUserDetails(userId);
    if (userDetails && userDetails.data) {
      setSelectedUserDetails(userDetails.data);
      setIsUpdateDialogOpen(true); // Open the dialog after setting state
    }
  };
  

  const handleUpdate = async (updatedUser: UserDetailsDTO) => {
    // Make your API call here using the existingManager and newManager.
    // After successful transfer, refresh your user listing if needed.
    if (updatedUser) {
      const tempData = updatedUser;
      //  tempData.manager= newManager;
      //  console.log(tempData);

      try {
        const updateDetailsResponse = await updateUserDetails(tempData);

        if (updateDetailsResponse.data) {
          setIsUpdateDialogOpen(false);
          setNotification(success("User updated Successfully"));
          setRefresh(Math.random());
        }
      } catch (e: any) {
        setNotification(failed(getErrorMessage(e.response.data)));
      }
    }
  };

  useEffect(() => {
    if (userList) {
      // Extracting unique organizations
      const orgs = userList.flatMap((entry) => Object.keys(entry.orgDetails));
      const uniqueOrgs = Array.from(new Set(orgs)).sort((a, b) =>
        a.localeCompare(b)
      );
      setOrganizations(uniqueOrgs);
    }
  }, [userList]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter(event.target.value);
  };
  // Filter entries by the selected organization
  const filteredEntries =
    userList &&
    userList.filter(
      (entry) => filter === "" || Object.keys(entry.orgDetails).includes(filter)
    );
  const handleDeleteDialog=async(userId:string)=>{
    await getUserDetails(userId);
    if(selectedUserDetails)
        console.log(selectedUserDetails)
         setDeleteUserDialogState(true);
  };

  const handleDeleteConfirm = async () => {
     if(selectedUserDetails){
          try {
            await deleteUser(selectedUserDetails.id);
            setRefresh(Math.random());
            setNotification(success("Operation Successfull"));
            
          } catch (error:any) {
            setNotification(failed(getErrorMessage(error)));
          }
        }
  };
  return (
    <Container component="main">
      <CssBaseline />
      {/* <TableComponent typeOfUser={"user"} onViewAssessmentClick={()=>navigate('/assessment/details')}/> */}
      <TitleBanner title={"Applicant Listing"} />
      {getRole == "SUPER_USER" && (
        <Box mt={2} mb={2}>
          <Stack direction="column">
            <FormControl fullWidth>
              <InputLabel id="org-select-label">Organization</InputLabel>
              <Select
                labelId="org-select-label"
                id="org-select"
                value={filter}
                label="Organization"
                onChange={handleFilterChange}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {organizations.map((org, index) => (
                  <MenuItem key={index} value={org}>
                    {org}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
      )}
      {!isEmpty(filteredEntries) ? (
        <TableContainer style={{ marginTop: "10px" }} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Average Score</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEntries &&
                filteredEntries.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      {user.firstName} {user.lastName}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <StyleChip label={user.totalScore} bgColor={"#f7fcd1"} />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() =>
                          navigate(
                            `/assessment/${btoa(user.assessmentId)}/details`
                          )
                        }
                        sx={{ marginRight: 2 }}
                        variant="outlined"
                        size="small"
                        disabled={isEmpty(user.assessmentId)}
                      >
                        View Assessment
                      </Button>
                      <Button
                        onClick={() => handleOpenTransferDialog(user.id)}
                        sx={{ marginRight: 2 }}
                        variant="outlined"
                        size="small"
                        disabled={
                          hasPermissions("transferDr", permissions) ||
                          getRole == "ADMINISTRATOR" ||
                          getRole == "SUPER_USER"
                            ? false
                            : true
                        }
                      >
                        Transfer
                      </Button>
                      <Button
                        onClick={() => handleOpenUpdateDialog(user.id)}
                        sx={{ marginRight: 2 }}
                        variant="outlined"
                        size="small"
                        disabled={
                          hasPermissions("editDr", permissions) ||
                          getRole == "ADMINISTRATOR" ||
                          getRole == "SUPER_USER"
                            ? false
                            : true
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => handleDeleteDialog(user.id)}
                        sx={{ marginRight: 2 }}
                        variant="outlined"
                        size="small"
                        disabled={getRole == "SUPER_USER" ? false : true}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoDataCard title={"No Applicant Data"} />
      )}

      <TransferDialog
        open={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setExistingManager("");
          setSelectedUserDetails(undefined);
        }}
        existingManager={existingManager}
        onTransfer={handleTransfer}
      />

      {selectedUserDetails && (
        <UpdateDialog
          open={isUpdateDialogOpen}
          onClose={() => {
            setIsUpdateDialogOpen(false);
            setSelectedUserDetails(undefined);
          }}
          user={selectedUserDetails}
          onUpdate={handleUpdate}
        />
      )}
      <DeleteUserDialog
        open={deleteUserDialogState}
        onClose={() => {
            setSelectedUserDetails(undefined)
            setDeleteUserDialogState(false)
        }}
        onDeleteConfirm={handleDeleteConfirm}
      />
    </Container>
  );
};
export default Listing;
