import { Container, CssBaseline, Box, Avatar, Typography, TextField, FormControlLabel, Checkbox, Button, Grid, Link } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/noshadow.png';
import { resetPassword } from "../../API/services";
import { isEmpty } from "lodash-es";
import { useNotificationStore } from "../../store/NotificationStore";
import { warning, failed, success } from '../Notification/NotificationMessages';



const ForgotPassword=()=>{
  const setNotification = useNotificationStore((state) => state.setNotification);
    const navigate= useNavigate();
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        if (isEmpty(email)) {
          setNotification(warning('Plesae Enter Email Address'));
          return;
      }
        try {
          // handleForgotPassword(data);
            await resetPassword(email?.toString()??"");
            setNotification(success('Password Reset Link Sent Successfully'));
        } catch (error) { 

          setNotification(failed('Operation Failed'));
        }
        console.log({
          email: data.get('email'),
        });
      };
      return(
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
       <img src={logo} alt="Logo" style={{ marginRight: '16px', height: '40px', width: '200px' }} />
          <Typography sx={{ mt: 1 }} component="h1" variant="h5">
            Please Enter Your Email
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
          
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
          
          </Box>
        </Box>
      </Container>
      );
}
export default ForgotPassword;