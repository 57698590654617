import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Tooltip,
} from "@mui/material";
import Link from "@mui/material/Link";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, object, string } from "yup";
import { useNotificationStore } from "../../store/NotificationStore";
import { userRegistration, validateAccessKey } from "../../API/services";
import { success, failed } from "../Notification/NotificationMessages";
import { getErrorMessage } from "../../utils/util";
import AccessKeyDialog from "./AccessKeyDialog";
import logo from "../../assets/noshadow.png";

const Register = () => {
  const setNotification = useNotificationStore(
    (state) => state.setNotification
  );
  const navigate = useNavigate();
  const { accessCode } = useParams<{ accessCode: string }>();

  const [showAccessKeyModal, setShowAccessKeyModal] = useState(!accessCode);
  const [accessKey, setAccessKey] = useState(accessCode || "");
  const [validationResponse, setValidationResponse] = useState<any>({});
  const [showRegistrationComplete, setShowRegistrationComplete] =
    useState(false);
  const [hrPermission, setHrPermission] = useState(false);

  const [organizationDetails, setOrganizationDetails] = useState({
    organizationAddress: "",
    organizationAddressZipCode: "",
    organizationAddressCity: "",
    organizationAddressState: "",
    organizationAddressProvince: "",
    organizationAddressCountry: "",
    organizationMailingAddress: "",
    organizationMailingAddressZipCode: "",
    organizationMailingAddressCity: "",
    organizationMailingAddressState: "",
    organizationMailingAddressProvince: "",
    organizationMailingAddressCountry: "",
  });

  const validationSchema = object().shape({
    password: string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    state: string().required("State is required"),
    // province: string().required("Country is required"),
    hrPermission: boolean().optional(),
   
      address: string().required("Address is required"),
city: string().required("City is required"),
zipCode: string().required("Zip Code is required"),
country: string().required("Country is required"),

  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const methods = useForm(formOptions);
  const { handleSubmit } = methods;

  useEffect(() => {
    if (accessKey) {
      console.log("Validating access key:", accessKey);
      validateKey();
    }
  }, [accessKey]);

  const validateKey = async () => {
    try {
      const response = await validateAccessKey({ accessKey });
      if (response.data) {
        console.log("Access key validation response:", response.data);
        setValidationResponse(response.data);
        if (response.data.registrationCompleted) {
          setShowRegistrationComplete(true);
        }
        setOrganizationDetails({
          organizationAddress: response.data.organizationAddress || "",
          organizationAddressZipCode: response.data.organizationAddressZipCode || "",
          organizationAddressCity: response.data.organizationAddressCity || "",
          organizationAddressState: response.data.organizationAddressState || "",
          organizationAddressProvince: response.data.organizationAddressProvince || "",
          organizationAddressCountry: response.data.organizationAddressCountry || "",
          organizationMailingAddress: response.data.organizationMailingAddress || "",
          organizationMailingAddressZipCode: response.data.organizationMailingAddressZipCode || "",
          organizationMailingAddressCity: response.data.organizationMailingAddressCity || "",
          organizationMailingAddressState: response.data.organizationMailingAddressState || "",
          organizationMailingAddressProvince: response.data.organizationMailingAddressProvince || "",
          organizationMailingAddressCountry: response.data.organizationMailingAddressCountry || "",
        });
      }
    } catch (error: any) {
      console.error("Error validating access key:", error);
      setNotification(failed(getErrorMessage(error.response?.data)));
    }
  };

  const onFormSubmit = (data: FieldValues) => {
    console.log("Form data:", data);
    if (accessKey) {
      const payload = {
        firstName: validationResponse.user?.firstName || "",
        lastName: validationResponse.user?.lastName || "",
        email: validationResponse.user?.email || "",
        group: "GRP",
        fullName: `${validationResponse.user?.firstName || ""} ${validationResponse.user?.lastName || ""}`,
        phone: "",
        province: data.country,
        state: data.state,
        address: data.address,
        zipCode: data.zipCode,
        country: data.country,
        yearOfBirth: "",
        hiringManager: hrPermission,
        password: data.password,
        organizationAddress: validationResponse.organizationAddress|| "",
        organizationMailingAddress: validationResponse.organizationMailingAddress|| "",
        organizationAddressCity: validationResponse.organizationAddressCity|| "",
        organizationAddressState: validationResponse.organizationAddressState|| "",
        organizationAddressZipCode: validationResponse.organizationAddressZipCode|| "",
        organizationAddressCountry: validationResponse.organizationAddressCountry|| "",
        organizationMailingAddressCity: validationResponse.organizationMailingAddressCity|| "",
        organizationMailingAddressState: validationResponse.organizationMailingAddressState|| "",
        organizationMailingAddressZipCode: validationResponse.organizationMailingAddressZipCode|| "",
        organizationMailingAddressCountry: validationResponse.organizationMailingAddressCountry|| "",
        organizationWebsite: validationResponse.organizationWebsite|| "",

        accessKey,
      };
      console.log("Submitting registration payload:", payload);
      userRegistration(payload)
        .then(() => {
          setNotification(success("Registered Successfully"));
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error during registration:", error);
          setNotification(failed(getErrorMessage(error.response?.data)));
        });
    }
  };

  const handleAccessKeySubmit = (key: string) => {
    console.log("Access key submitted:", key);
    setAccessKey(key);
    setShowAccessKeyModal(false);
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <AccessKeyDialog
          showAccessKeyModal={showAccessKeyModal}
          onClose={() => navigate("/login")}
          onSubmit={handleAccessKeySubmit}
        />
        <Dialog
          open={showRegistrationComplete}
          onClose={() => {
            setShowRegistrationComplete(false);
            navigate("/login");
          }}
        >
          <DialogContent>
            <Typography variant="h6">
              This Entity has been registered already! Login to continue
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{ height: "40px", width: "200px" }}
          />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={handleSubmit(onFormSubmit)}
              sx={{ mt: 3 }}
            >
              {/* Organization Details */}
              <Typography variant="h6" sx={{ mb: 2 }}>
                Organization Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    fullWidth
                    label="Organization Name"
                    value={validationResponse.organizationName || ""}
                  />
                
                </Grid>
                <Grid item xs={12}>
                    
                        <TextField
                        
                          fullWidth
                          label="Organization Website"
                          value={validationResponse.organizationWebsite || ""}
                          
                          placeholder="https://www.example.com"
                          InputProps={{ readOnly: true }}
                        />
                      
                      
                      
                    
                  </Grid>
              </Grid>
              

              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Organization Address Details
              </Typography>
              <Divider sx={{ mb: 3 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  
                      <TextField
                        
                        fullWidth
                        label="Address"
                        value={organizationDetails.organizationAddress}
                        
                      
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                
                      <TextField
                       
                        fullWidth
                        label="City"
                        value={organizationDetails.organizationAddressCity}
                        
                        
                      />
                   
                </Grid>
                <Grid item xs={12} sm={6}>
                 
                      <TextField
                       
                        fullWidth
                        label="State"
                        value={organizationDetails.organizationAddressState}
                        
                      />
                  
                </Grid>
                <Grid item xs={12} sm={6}>
                  
                      <TextField
                        
                        fullWidth
                        label="Zip Code"
                        value={organizationDetails.organizationAddressZipCode}
                        
                        
                      />
                   
                </Grid>
                <Grid item xs={12} sm={6}>
                  
                      <TextField
                       
                        fullWidth
                        label="Country"
                        value={organizationDetails.organizationAddressCountry}
                        
                        
                      />
                   
                </Grid>
              </Grid>

              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Organization Mailing Address Details
              </Typography>
              <Divider sx={{ mb: 3 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                 
                      <TextField
                       
                        fullWidth
                        label="Mailing Address"
                        value={organizationDetails.organizationMailingAddress}
                       
                        InputProps={{ readOnly: true }}
                      />
                   
                </Grid>
                <Grid item xs={12} sm={6}>
                 
                      <TextField
                        
                        fullWidth
                        label="City"
                        value={organizationDetails.organizationMailingAddressCity}
                        
                        InputProps={{ readOnly: true }}
                      />
                   
                </Grid>
                <Grid item xs={12} sm={6}>
                  
                      <TextField
                       
                        fullWidth
                        label="State"
                        value={organizationDetails.organizationMailingAddressState}
                       
                        InputProps={{ readOnly: true }}
                      />
                    
                </Grid>
                <Grid item xs={12} sm={6}>
                  
                      <TextField
                        
                        fullWidth
                        label="Zip Code"
                        value={organizationDetails.organizationMailingAddressZipCode}
                        
                        InputProps={{ readOnly: true }}
                      />
                   
                </Grid>
                <Grid item xs={12} sm={6}>
                  
                      <TextField
                        fullWidth
                        label="Country"
                        value={organizationDetails.organizationMailingAddressCountry}
                        
                        InputProps={{ readOnly: true }}
                      />
                    
                </Grid>
              </Grid>

              {/* Account Owner */}
              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Account Owner
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    fullWidth
                    label="First Name"
                    value={validationResponse.user?.firstName || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    fullWidth
                    label="Last Name"
                    value={validationResponse.user?.lastName || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    fullWidth
                    label="Email"
                    value={validationResponse.user?.email || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Password"
                        type="password"
                        error={!!methods.formState.errors.password}
                        helperText={methods.formState.errors.password?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="address"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Address"
                        error={!!methods.formState.errors.address}
                        helperText={methods.formState.errors.address?.message}
                      />
                    )}
                  />
                </Grid> <Grid item xs={12} sm={6}>
                  <Controller
                    name="city"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="City"
                        error={!!methods.formState.errors.city}
                        helperText={methods.formState.errors.city?.message}
                      />
                    )}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="state"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="State"
                        error={!!methods.formState.errors.state}
                        helperText={methods.formState.errors.state?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="zipCode"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Zip Code"
                        error={!!methods.formState.errors.zipCode}
                        helperText={methods.formState.errors.zipCode?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="country"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Country"
                        error={!!methods.formState.errors.country}
                        helperText={methods.formState.errors.country?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* Hiring Manager Permission Checkbox with Tooltip */}
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...methods.register("hrPermission")}
                    checked={hrPermission}
                    onChange={(e) => setHrPermission(e.target.checked)}
                  />
                }
                label="Do you want Hiring Manager permission?"
              />
              <Tooltip title="This option gives you additional permissions for managing hiring processes.">
                <HelpOutlineIcon sx={{ ml: 1, cursor: "pointer", color: "gray" }} />
              </Tooltip>
            </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Finish Registration
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Container>
    </>
  );
};

export default Register;
