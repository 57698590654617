import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Stack, Typography } from "@mui/material";

interface SkillsCardType {
  title: string | ReactNode;
  url?: string;
  bgColor?: string;
  growSkill: string;
  screenSkill: string;
  styles?: any;
}

const SkillsCard = ({
  title,
  url,
  bgColor = "#f0f0f0", // Default background color
  growSkill,
  screenSkill,
  styles = {}, // Default to empty styles
}: SkillsCardType) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        ...styles,
        m: 1,
        flexGrow: 1,
        boxShadow: 2,
        borderRadius: "22px",
        backgroundColor: bgColor,
        cursor:  "pointer" , // Cursor pointer 
        transition: "transform 0.2s", // Smooth hover animation
        "&:hover": {
          transform: "scale(1.03)", // Scale on hover
        },
      }}
      onClick={() => url && navigate(url)} // Handle navigation
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        {/* Title */}
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            mb: 1,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>

        {/* Skills Section */}
        <Stack spacing={1}>
          {/* Screen Skill */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: "0.9rem", fontWeight: "600" }}>
              SCREEN:
            </Typography>
            <Typography
              sx={{
                fontSize: "0.8rem",
                flexGrow: 1,
                textAlign: "right",
                textTransform: "capitalize",
              }}
            >
              {screenSkill}
            </Typography>
          </Stack>

          {/* Grow Skill */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: "0.9rem", fontWeight: "600" }}>
              GROW:
            </Typography>
            <Typography
              sx={{
                fontSize: "0.8rem",
                flexGrow: 1,
                textAlign: "right",
                textTransform: "capitalize",
              }}
            >
              {growSkill}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default SkillsCard;
