import { useState, useEffect } from "react";
import { getAssessmentHistory } from "../../API/services";
import { assessmentHistoryType } from "../../utils/Types/DTO";
import { formatDate, getCookieByName, getLoggedInUser, getRoleToken } from "../../utils/util";
import { Container, CssBaseline, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Chip, styled, Stack, Typography, Select, MenuItem, Box, InputLabel, FormControl } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import TitleBanner from "../TitleBanner/TitleBanner";
import { useUserContext } from "../../context/UserContext";
type props = {
    bgColor: string;
};
const StyleChip = styled(Chip)(({ bgColor }: props) => ({
    backgroundColor: bgColor,
    borderRadius: 3,
}));
const AssessmentHistory = () => {
    const user = useUserContext();
    const getRole = getRoleToken();
    const orgs = user.id ? Object.keys(user.organizationMap).map((data: string) => {
        return { id: data, value: user.organizationMap[data] }
    }) : [];
    orgs.push({ id: '', value: 'All' });
    const [selectOrg, setSelectedOrg] = useState('');
    const navigate = useNavigate();
    let { candidateEmail } = useParams<{ candidateEmail: string }>();
    candidateEmail = candidateEmail ? atob(candidateEmail ?? "") : user.id;
    const [assessmentHistory, setAssessmentHistory] = useState<assessmentHistoryType[] | null>(null);
    useEffect(() => {
        try{
            const fetchAssessmentHistory = async () => {
                if (candidateEmail) {
                    const response = await getAssessmentHistory(candidateEmail ?? '');
                    if (response.data) setAssessmentHistory(response.data);
                }
            };
            fetchAssessmentHistory();
        }catch(e){
            setAssessmentHistory([])
        }
    }, []);

    const changeOrg = (e: any) => {
        // if (assessmentHistory != null) {
        //     setAssessmentHistory(assessmentHistory?.filter((data) => data.organizationId === e.target.value))
        // }
        setSelectedOrg(e.target.value)
    }
    const filteredAssessments = selectOrg
    ? assessmentHistory?.filter(a => a.organizationId === selectOrg)
    : assessmentHistory;
    return (
        <Container component="main">
            <CssBaseline />
            <TitleBanner title={"Assessment History"} />
            {
                getRole !="SUPER_USER" && (
                    <Box mt={2} mb={2}>
                <Stack direction="column">
                <FormControl fullWidth>
                <InputLabel id="skill-select-label">Filter through organization</InputLabel>
                    <Select
                        labelId="assessment-select-label"
                        value={selectOrg}
                        onChange={changeOrg}
                        label="Filter through organization"
                        id="assessment-select-label"
                    >
                        {orgs.map(org => (
                            <MenuItem key={org.id} value={org.id}>
                                {org.value}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Stack>
            </Box>
                )
            }
            
            <TableContainer style={{ marginTop: '10px' }} component={Paper}>
                <Table stickyHeader>
                    <TableHead>

                        <TableRow>
                            <TableCell >Assessment Type</TableCell>
                            <TableCell >Email</TableCell>
                            <TableCell >Average Score</TableCell>
                            <TableCell >Status</TableCell>
                            <TableCell >Date Time</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {
                            filteredAssessments && (
                                <>
                                    {filteredAssessments.length > 0 && (
                                        <>
                                            {filteredAssessments.map((assessment) => (
                                                <TableRow key={assessment.id}>
                                                    <TableCell>{assessment.assessmentType}</TableCell>
                                                    <TableCell>{assessment.email}</TableCell>
                                                    <TableCell><StyleChip label={assessment.totalScore} bgColor={"#f7fcd1"} /></TableCell>
                                                    <TableCell><StyleChip label={assessment.responseProcessed ? "Processed" : "Not-Processed"} bgColor={assessment.responseProcessed ? "#d1fcd7" : "#f5aca4"} /></TableCell>
                                                    <TableCell>{formatDate(assessment.dateTime)}</TableCell>
                                                    <TableCell align="right">
                                                        <Button
                                                            onClick={() => navigate(`/assessment/${btoa(assessment.id)}/details`)}
                                                            sx={{ marginRight: 2 }}
                                                            variant="outlined"
                                                            size="small"
                                                            disabled={!assessment.responseProcessed}
                                                        >
                                                            View Assessment
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    )}
                                </>
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>
            {assessmentHistory && assessmentHistory.length <= 0 && ((
                        <Box textAlign="center" mt={20}>
                            <Typography variant="h6">No history found</Typography>
                        </Box>
                    ))}
        </Container>
    );

};
export default AssessmentHistory;
