import {isEmpty} from "lodash-es";
import { logoutUserCall } from "../API/services";
import { ErrorDTO } from "./Types/DTO";

export const storeSession=(authToken:string)=>{localStorage.setItem('authToken', authToken);
return true;
}


export function setCookieByName(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export function getCookieByName(name: string) {
    const pattern = RegExp(name + '=.[^;]*');
    const matched = document.cookie.match(pattern);
    if (matched) {
      const cookie = matched[0].split('=');
      return cookie[1];
    }
    return null;
};

export const getAuthToken = () => getCookieByName('Authorization');
export const getLoggedInUser = () => getCookieByName('loggedInUser');

export const getSessionToken = () => getCookieByName('SessionID');

export const getRoleToken = () => getCookieByName('ROLE');
export const getPermissions = () => getCookieByName('PERMISSIONS');
export const getAccountOwner = () => getCookieByName('ACCOUNT_OWNER');

function deleteCookieByName(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
export const logoutUser = async () => {
  const sessionID = getSessionToken();
  console.log(sessionID);
  console.log('Logged OUT');
  
  // document.cookie = 'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  if (sessionID) {
    await logoutUserCall(sessionID);
  }
  deleteCookieByName('Authorization');
  deleteCookieByName('SessionID');
  deleteCookieByName('loggedInUser');
  deleteCookieByName('ROLE');
  deleteCookieByName('PERMISSIONS');
  deleteCookieByName('ACCOUNT_OWNER');
  global.window.sessionStorage?.clear()

  window.location.href = '/login';
};

export const invalidateTokens = async () => {
  const sessionID = getSessionToken();
  // console.log(sessionID);
  console.log('Logged OUT');
  deleteCookieByName('Authorization');
  deleteCookieByName('SessionID');
  deleteCookieByName('loggedInUser');
  deleteCookieByName('ROLE');
  deleteCookieByName('PERMISSIONS');
  deleteCookieByName('ACCOUNT_OWNER');

  window.location.href = '/login';
};

export const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const randomGenerator=()=>{
  const value =Math.random();
      return{
          score: Math.round(value*100),
          result: value*100 < 0.5*100
  }
};
      
// export function sleep(ms = 2000): Promise<void> {
//   console.log('Kindly remember to remove `sleep`');
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

export const generateAccessCode = () => {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let uniqueId = "";

  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    uniqueId += charset.charAt(randomIndex);
  }

  return uniqueId;
};


export const capitalizer = (str: string) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
export const hexColors = [
  "#9ceb38",
  "#1db010",
  "#fff624",
  "#245ad1",
  "#ee08f2",
  "#a4f2f7",
  "#f2a429",
  "#ded08a",
  "#d65118",
  "#7a1c9c",
  "#1c9c9c"
];


export const formatDate=(dateString:string)=> {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0 indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
export const permissionsString=["grow", "directReports","screen", "addDr", "editDr", "removeDr", "transferDr", "hr", "global", ];
export  const hasPermissions = (requiredPermission:string,permissions:string[])=> permissions.includes(requiredPermission);

export const evaluationColors= [
  {
    evaluation:'NOT_RECOMMENDED',
    color:'#fcd1d1',
  },
  {
    evaluation:'HIGHLY_RECOMMENDED',
    color:'#d1fcd7',
  },
  {
    evaluation:'RECOMMENDED',
    color:'#D1E9FC',
  },
  {
    evaluation:'POSSIBLE',
    color:'#f7fcd1',
  }
];
export const openInNewTab = (url:string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  export const getErrorMessage =(error: unknown)=>{
    // Check if the error is an instance of BackendError
    if (typeof error === 'object' && error !== null && 'message' in error) {
      const backendError = error as unknown as ErrorDTO;
      // Return the errorMessage from the backend error
      console.log(backendError);
      return backendError.message;
    }
    
    // If the error is not of type BackendError or the structure is unexpected
    // return a generic error message
    return 'An unexpected error occurred. Please try again later.';
  }

  export const formatIsoDate=(isoDate:string) =>{
    const date = new Date(isoDate);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };
 export const assessmentsArray = [
    // { id: "ASMT-22288", name: "ESAT™ Grow Promo" },
    { id: "ASMT-20827", name: "ESAT™ Grow" },
    { id: "ASMT-20824", name: "ESAT™ Screen" },
    // { id: "ASMT-21499", name: "ESAT™ Screen Promo" }
    // ... add other assessments here
];

export const getAssessmentName = (id: string) => {
    const assessment = assessmentsArray.find(assessment => assessment.id === id);
    return assessment ? assessment.name : '';
};

//* "NOT_RECOMMENDED" into "Not Recommended"
export const convertToCapitalizedWords = (str: string = "") => {
  return str
    .toLowerCase()
    .split("_")
    .map((word: any) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    .join(" ")
}

export const permissionLabels: { [key: string]: string } = {
  directReports: 'Direct Reports (DR)',
  addDr: "Add DR's",
  editDr: "Edit DR's",
  removeDr: "Remove DR's",
  transferDr: "Transfer DR's",
  hr: 'HR',
  global: 'Global (all access)',
  screen: 'Screen',
  grow: 'Grow',
}