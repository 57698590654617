import React, { useState, useEffect } from 'react';
import { AdminListingDTO, UserDetailsDTO } from '../../utils/Types/DTO';
import { get, set } from 'lodash';
import { getAdministrators, getLoggedInUserDetails, updateUserDetails,getAdministratorsByOrgID } from '../../API/services';
import { getCookieByName, getErrorMessage } from '../../utils/util';
import { Box, Stack, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Container, CssBaseline } from '@mui/material';
import TitleBanner from '../../components/TitleBanner/TitleBanner';
import UpdateDialog from '../UserListing/UpdateUserDialog';
import { useNotificationStore } from '../../store/NotificationStore';
import { failed, success } from '../../components/Notification/NotificationMessages';
import ViewDialog from '../UserListing/ViewUserDialog';

const AdminListing: React.FC = () => {
    const [listings, setListings] = useState<AdminListingDTO[]>([]);
    const [filter, setFilter] = useState('');
    const [organizations, setOrganizations] = useState<string[]>([]);
    const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
    const [selectedUserDetails, setSelectedUserDetails] = useState<UserDetailsDTO>();
    const setNotification = useNotificationStore((state) => state.setNotification);
    useEffect(() => {
        const fetchAdminListings = async () => {

            try {

                const response = await getAdministrators(getCookieByName('userId') ?? '');
                if (response.data) setListings(response.data);
            } catch (error) {
                console.error('Error fetching admin listings:', error);
            }
        };
        // Fetch the admin listings from the server
        fetchAdminListings();
    }, []);
    useEffect(() => {
        // Extracting unique organizations
        const uniqueOrgs = Array.from(new Set(listings.map(entry => Object.keys(entry.orgDetails)[0]))).sort((a, b) => a.localeCompare(b)); // Sort alphabetically;
        setOrganizations(uniqueOrgs);
    }, [listings]);

    const handleFilterChange = (event: SelectChangeEvent<string>) => {
        setFilter(event.target.value);
    };

    const getUserDetails = async (userId: string) => {
        const loggedInUserDetailsResponse = await getLoggedInUserDetails(userId);
        if (loggedInUserDetailsResponse.data) setSelectedUserDetails(loggedInUserDetailsResponse.data);
        return loggedInUserDetailsResponse.data;
    };

    // Filter entries by the selected organization
    const filteredEntries = listings.filter(entry => Object.keys(entry.orgDetails)[0] === filter || filter === '');


    const handleOpenUpdateDialog = async (userId: string, viewOnly:boolean) => {
        await getUserDetails(userId);
        //    setExistingManager(data.manager);
        viewOnly? setIsViewDialogOpen(true):setIsUpdateDialogOpen(true);

    };

    const handleUpdate = async (updatedUser: UserDetailsDTO) => {
        // Make your API call here using the existingManager and newManager.
        // After successful transfer, refresh your user listing if needed.
        if (updatedUser) {
            const tempData = updatedUser;
            tempData.permissions = selectedUserDetails?.permissions ?? '';
            tempData.role = selectedUserDetails?.role ?? '';
            tempData.manager = selectedUserDetails?.manager ?? '';
            tempData.organizationMap = selectedUserDetails?.organizationMap ?? {};

            //  tempData.manager= newManager;
            //  console.log(tempData);

            try {
                const updateDetailsResponse = await updateUserDetails(tempData);

                if (updateDetailsResponse.data) {
                    setIsUpdateDialogOpen(false);
                    setNotification(success('User updated Successfully'));
                }
            } catch (e: any) {
                setNotification(failed(getErrorMessage(e.response.data)));
            }
        }

    };

    return (
        <Container component="main">
        <CssBaseline />
            <TitleBanner title={"Administrators Listing"} />
            <Box mt={2} mb={2}>
                <Stack direction="column">
                    <FormControl fullWidth>
                        <InputLabel id="org-select-label">Organization</InputLabel>
                        <Select
                            labelId="org-select-label"
                            id="org-select"
                            value={filter}
                            label="Organization"
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {organizations.map((org, index) => (
                                <MenuItem key={index} value={org}>{org}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Box>
            <TableContainer style={{ marginTop: '10px' }} component={Paper}>
                <Table stickyHeader>
                    <TableHead><TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow></TableHead>
                    <TableBody>
                        {
                            filteredEntries && (
                                filteredEntries.map((entry) => (
                                    <TableRow key={entry.id}>
                                        <TableCell>{entry.firstName} {entry.lastName}</TableCell>
                                        <TableCell>{entry.email}</TableCell>
                                        <TableCell align="right">
                                        <Button sx={{ marginRight: 2 }}
                                                    variant="outlined"
                                                    size="small"
                                                     onClick={() => handleOpenUpdateDialog(entry.id,true)}>View Details</Button>
                                            <Button sx={{ marginRight: 2 }}
                                                    variant="outlined"
                                                    size="small"onClick={() => handleOpenUpdateDialog(entry.id,false)}>Edit</Button>
                                            
                                        </TableCell>
                                    </TableRow>
                                ))
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedUserDetails && (
                <>
                <UpdateDialog
                    open={isUpdateDialogOpen}
                    onClose={() => setIsUpdateDialogOpen(false)}
                    user={selectedUserDetails}
                    onUpdate={handleUpdate} />

                    <ViewDialog
                     open={isViewDialogOpen} 
                     onClose={
                       ()=> setIsViewDialogOpen(false)
                     } user={selectedUserDetails} />
                    </>
            )}
        </Container>
    );
};

export default AdminListing;