import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PopupContextProps {
  showPopup: () => void;
  hidePopup: () => void;
  isPopupVisible: boolean;
}

interface PopupProviderProps {
  children: ReactNode; // Define `children` type explicitly
}

const PopupContext = createContext<PopupContextProps | undefined>(undefined);

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const showPopup = () => setPopupVisible(true);
  const hidePopup = () => setPopupVisible(false);

  return (
    <PopupContext.Provider value={{ showPopup, hidePopup, isPopupVisible }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
};
