import { create } from 'zustand';
import { getLicenseAvailable } from '../API/services';
import { UserDetailsDTO, SubscriptionDTO } from '../utils/Types/DTO';

interface SubscriptionState {
  hasActiveSubscription: boolean;
  setActiveSubscription: (status: boolean) => void;
  checkSubscriptionStatus: (user: UserDetailsDTO) => Promise<void>;
}

export const useSubscriptionStore = create<SubscriptionState>((set) => ({
  hasActiveSubscription: true,

  setActiveSubscription: (status: boolean) => set({ hasActiveSubscription: status }),

  checkSubscriptionStatus: async (user: UserDetailsDTO) => {
    const organizationIds = Object.keys(user.organizationMap);
    let hasSubscription = false;
    const isDemoDateValid = (demoEndDate: string | null) => {
      if (!demoEndDate) return false;
      const currentDate = new Date();
      const demoDate = new Date(demoEndDate);
      return demoDate > currentDate; // Demo date is valid if it's in the future
    };

    for (const orgId of organizationIds) {
      try {
        const response = await getLicenseAvailable(orgId);
        const data = response.data;

        // Check if demoEndDate is valid
        if (isDemoDateValid(data.demoEndDate)) {
          hasSubscription = true;
          break;
        }

        if (data.esatSubscriptions?.some((sub: SubscriptionDTO) => sub.status === 'active')) {
          hasSubscription = true;
          break;
        }
      } catch (error) {
        console.error(`Error checking subscription for org ${orgId}:`, error);
      }
    }

    set({ hasActiveSubscription: hasSubscription || user.organizationOwner === true });
  },
}));
